import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMatomo from 'vue-matomo'

// plugins
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

// bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


Vue.use(VueMatomo, {
  host: 'https://matomo.text-analytics.ch/',
  siteId: '18',
  router: router,
  disableCookies: true,
});


let vueInstance;

// Fonction asynchrone pour attendre que $matomo soit défini
async function initializeApp() {
  await new Promise((resolve) => {
    if (Vue.prototype.$matomo) {
      resolve();
    } else {
      setTimeout(resolve, 100); // Attendez 100ms avant de réessayer
    }
  });

  vueInstance = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');

  window._paq.push(['trackPageView']);
}

initializeApp();

export { vueInstance }; // Exportez vueInstance si nécessaire
